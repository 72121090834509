<template>
  <div>
    <apexchart type="bar"
               height="350"
               :options="chartOption"
               :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: "barChart",
  props: {
    labels: {
      type: String | Array,
      default: "",
    },
    series: {
      type: String | Array,
      default: "",
    },
  },
  data() {
    return {
      chartOption: [],
    };
  },
  created() {
    this.setChartOption();
  },
  methods: {
    setChartOption() {
      this.chartOption = {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            barHeight: "70%",
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          colors: [
            "#F44336",
            "#e1b769",
            "#86d8bd",
            "#6891df",
            "#b769e1",
            "#d8bd86",
            "#91df68",
            "#f5a623",
            "#f55c23",
            "#5c23f5",
            "#23f5a6",
          ],
        },
        tooltip: {
          enabled: false,
          // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          //   return (
          //     '<div class="arrow_box p-3">' +
          //     "<span><b>Kullanım Sayısı : </b>" +
          //     series[seriesIndex][dataPointIndex] +
          //     "</span>" +
          //     "</div>"
          //   );
          // },
        },
        xaxis: {
          categories: this.labels,
          labels: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            enabled: false,
            formatter: function (val) {
              return val.toLocaleString("tr-TR", {
                maximumFractionDigits: 0,
              });
            },
            style: {
              fontSize: "10px",
            },
            trim: true,
            hideOverlappingLabels: true,
          },
        },
      };
    },
  },
  watch: {
    labels: function () {
      this.setChartOption();
    },
    series: function () {
      this.setChartOption();
    },
  },
};
</script>
